

























import Vue from 'vue';
import SecondaryButton from './SecondaryButton.vue';

export default Vue.extend({
  name: 'ConnectResult',
  components: {
    SecondaryButton,
  },
  props: {
    text: String,
  },
  methods: {
    goBack(): void {
      this.$router.push({ name: 'Overview' });
    },
  },
});

