





























































































































import Vue from 'vue';
import {
  MdCard, MdCardHeader, MdCardContent, MdButton,
  // @ts-ignore
} from 'vue-material/dist/components';
import moment from 'moment';
import { Statistics, Status } from '../models/Statistics';
import { toTitleCase } from '../utils/helpers';
import { STATISTICS_URL } from '../environment';
import PrimaryButton from '../components/PrimaryButton.vue';

Vue.use(MdCard, MdCardHeader, MdCardContent, MdButton);

interface DashboardData {
  statistics: Statistics;
  lastFetched: string | null;
}

export default Vue.extend({
  name: 'Dashboard',
  components: {
    PrimaryButton,
  },
  data: (): DashboardData => ({
    statistics: {
      accounts: {
        count: 0,
        childWithOnlyOneParent: 0,
        allowEmailConsents: 0,
        allowSmsConsents: 0,
        autoApproves: 0,
      },
      shellAccounts: 0,
      parent1Accounts: 0,
      parent2Accounts: 0,
      children: 0,
      purchases: [],
      prices: {
        min: 0, max: 0, average: 0, sum: 0,
      },
      autoApprovals: {
        min: 0, max: 0, average: 0, sum: 0,
      },
      amountOfAutoApprovals: 0,
      logins: { sevenDays: 0, thirtyDays: 0 },
      clearLogs: 0,
      swishLogs: 0,
      reference: [{ _id: 'Hämtar..', count: 0 }],
    },
    lastFetched: null,
  }),
  computed: {
    sumOfProposals(): number {
      return this.statistics.purchases
        .reduce((sum: number, current: Status) => sum + current.count, 0);
    },
    numberOfProposalsInAveragePerAccount(): string {
      return (this.sumOfProposals / this.statistics.accounts.count).toFixed(1);
    },
    numberOfProposalsInAveragePerChild(): string {
      return (this.sumOfProposals / this.statistics.children).toFixed(1);
    },
    clearedByButton(): number {
      return this.statistics.clearLogs - this.statistics.swishLogs;
    },
  },
  methods: {
    loadData(): void {
      this.$http.get(STATISTICS_URL)
        .then((result: any): Statistics => result.data)
        .then((statistics: Statistics) => {
          console.log(statistics);
          this.statistics = statistics;
          this.lastFetched = moment().format('YYYY-MM-DD HH:mm:ss');
        });
    },
    titleCase(text: string): string {
      return toTitleCase(text);
    },
  },
  mounted(): void {
    this.$nextTick(() => {
      // @ts-ignore
      this.loadData();
    });
  },
});
