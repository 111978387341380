import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null,
    child: null,
    purchases: null,
    responder: null,
    registrationData: null,
    token: null,
  },
  mutations: { // syncronous
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setChild(state, payload) {
      state.child = payload;
    },
    setPurchases(state, payload) {
      state.purchases = payload;
    },
    setResponder(state, payload) {
      state.responder = payload;
    },
    setRegistrationData(state, payload) {
      state.registrationData = payload;
    },
    resetRegistrationData(state) {
      state.registrationData = null;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    reset(state) {
      state.currentUser = null;
      state.child = null;
      state.purchases = null;
      state.responder = null;
      state.token = null;
    },
  },
  modules: {},
  getters: {
    getCurrentUser: (state) => state.currentUser,
    getChild: (state) => state.child,
    getPurchases: (state) => state.purchases,
    getResponder: (state) => state.responder,
    getRegistrationData: (state) => state.registrationData,
  },
  plugins: [new VuexPersistence().plugin],
});
