<template>
  <div class="page-container">
    <md-toolbar class="md-primary">
      <md-button class="md-icon-button" @click="showNavigation = true">
        <md-icon class="header-icon">menu</md-icon>
      </md-button>
      <div
        class="row align-items-center"
        @click="refresh"
      >
        <img
          class="nav-logo margin-right-8"
          :src="require('../assets/logofamiljefixbeta.svg')"
          style="height: 17px; margin-top: 3px;">
      </div>
    </md-toolbar>

    <md-drawer :md-active.sync="showNavigation" md-swipeable>
      <div class="drawer-container column">
        <div class="profile-container padding-16 margin-top-16">
          <router-link class="profile margin-bottom-16" :to="{ name: 'Redigera profil' }">
            <span v-on:click="showNavigation = false">
              <img
                class="avatar-img margin-bottom-16"
                v-if="userImg"
                :src="userImg"
                alt="avatar"
              />
              <img
                class="avatar-img margin-bottom-16"
                v-else
                :src="require('../assets/defaultprofile.png')"
                alt="avatar"
              />
              <h3 class="headline-3">{{user.name}}</h3>
            </span>
          </router-link>
        </div>

        <div class="main-container padding-16">
          <div class="feature-container">
            <md-list>
              <md-list-item class="cart-link">
                <md-icon class="outlined">shopping_cart</md-icon>
                <span class="md-list-item-text" @click="showNavigation = false">
                  <a
                  @click="refresh">
                  <span class="body-1">Inköp</span>
                  </a>
                </span>
              </md-list-item>

              <md-list-item class="child-link">
                <md-icon class="outlined">child_care</md-icon>
                <span class="md-list-item-text" @click="showNavigation = false">
                  <router-link
                    tag="li"
                    :to="{ name: 'Redigera barn' }">
                      <span class="body-1">Barnets profil</span>
                  </router-link>
                </span>
              </md-list-item>

              <md-list-item class="profile-link">
                <md-icon class="outlined">perm_identity</md-icon>
                <span class="md-list-item-text" @click="showNavigation = false">
                  <router-link
                    tag="li"
                    :to="{ name: 'Redigera profil' }">
                    <span class="body-1">Din profil</span>
                  </router-link>
                </span>
              </md-list-item>
            </md-list>
          </div>

          <div class="company-container">
            <md-list>
              <md-list-item>
                <md-icon class="outlined">info</md-icon>
                <span class="md-list-item-text" @click="showNavigation = false">
                  <router-link
                  tag="li"
                  :to="{ path: '/pp' }">
                  <span class="body-1">Privacy policy</span>
                  </router-link>
                </span>
              </md-list-item>
              <md-list-item>
                <md-icon class="outlined">info</md-icon>
                <span class="md-list-item-text" @click="showNavigation = false">
                  <router-link
                  tag="li"
                  :to="{ path: '/tos' }">
                  <span class="body-1">Terms and conditions</span>
                  </router-link>
                </span>
              </md-list-item>
              <md-list-item>
                <md-icon class="outlined">contact_support</md-icon>
                <span class="md-list-item-text" @click="showNavigation = false">
                  <a
                    class="body-1"
                    tag="li"
                    href="mailto:hej@familjefix.se" target="_blank">Kontakta Familjefix
                  </a>
                </span>
              </md-list-item>
              <md-list-item class="footer-container" @click="logout">
              <md-icon>logout</md-icon>
              <span class="md-list-item-text body-1">
                Logga ut
              </span>
            </md-list-item>
            </md-list>
          </div>
        </div>
      </div>
    </md-drawer>

    <md-content>
      <router-view/>
    </md-content>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import VueMaterial from 'vue-material';
import { IMAGE_URL, PURCHASE_URL } from '../environment';

Vue.use(VueMaterial);

export default Vue.extend({
  name: 'NavigationDrawer',
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
      child: 'getChild',
    }),
    userImg() {
      return (!this.user.imageName) ? '' : `${IMAGE_URL}${this.user.imageName}`;
    },
  },
  data: () => ({
    showNavigation: false,
  }),
  methods: {
    logout() {
      this.$store.commit('reset');
      this.$router.push({ name: 'Home' });
    },
    refresh() {
      if (this.$route.name === 'Overview') {
        this.$http.get(`${PURCHASE_URL}${this.child.publicId}`)
          .then((res) => {
            this.$store.commit('setPurchases', res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ name: 'Overview' });
      }
    },
  },
});
</script>

<style lang="scss" scoped>

.page-container {
  background: #ffffff;
  z-index: 5;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  .md-toolbar {
    padding: 8px;

    div {
      .nav-logo {
        height: 32px;
      }
    }
  }

  .md-drawer {
    position: fixed;
    top: 0;
    width: 304px;
    max-width: calc(100vw - 40px);
    max-height: 100vh;

    .drawer-container {
      height: 100%;

      .profile-container {
        border-bottom: 1px solid rgba(33, 33, 33, 0.08);

        .profile {
          .avatar-img {
            z-index: 6;
            width: 48px;
            height: 48px;
            border-radius:50%;
          }
        }
      }

      .main-container {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        justify-content: space-between;
      }

      .footer-container {
        border-top: 1px solid #c4c4c4;
      }
    }
  }
}

.md-icon.md-icon-font.md-theme-default {
  Width: 18px;
  Height: 12px;
  color: #000000;
}

.md-toolbar.md-theme-default.md-primary {
  background: #F5F5F5;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}

.md-icon.md-icon-font.header-icon.md-theme-default{
  Width: 18px;
  Height: 12px;
  color: #000000;
}

.md-list-item-content>.md-icon:first-child {
  margin-right: 16px;
}

span li.router-link-active,
span li.router-link-exact-active {
  color: rgba(0, 0, 0, 1);
}

.md-theme-default a:not(.md-button) {
  color: var(--md-theme-default-primary-on-background, #000000);
}
</style>
