import Vue from 'vue';
import VueRouter, { NavigationGuardNext, RouteConfig, Route } from 'vue-router';
import VueCookies from 'vue-cookies';
import StartPage from '../views/StartPage.vue';
import CompetitionPage from '../views/CompetitionPage.vue';
import CompetitionTerms from '../views/CompetitionTerms.vue';
import Login from '../views/Login.vue';
// import InvitePage from '../views/InvitePage.vue';
import Register from '../views/Register.vue';
import Overview from '../views/Overview.vue';
import NoChild from '../views/NoChildView.vue';
import AddedChild from '../views/ChildAdded.vue';
import RegisterChild from '../views/RegisterChild.vue';
import CreatePurchase from '../views/CreatePurchase.vue';
import ViewPurchase from '../views/ViewPurchase.vue';
import SplitPurchases from '../views/SplitPurchases.vue';
import store from '../store';
// import Purchase from '../models/Purchase';
import Dashboard from '../views/Dashboard.vue';
import CookieView from '../views/CookieView.vue';
import TermsView from '../views/TermsView.vue';
import ConnectResult from '../components/ConnectResult.vue';
import {
  // NODE_ENV,
  INVITE_COOKIE_SECRET,
  INVITE_COOKIE_NAME,
  INVITE_COOKIE_CODE,
} from '../environment';
// import { component } from 'vue/types/umd';

Vue.use(VueRouter);
Vue.use(VueCookies);

const requireAuthentication = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  // if (NODE_ENV === 'staging') {
  //   const inviteCookie = Vue.$cookies.get(INVITE_COOKIE_NAME) as string;
  //   if (!inviteCookie) next({ name: 'Invite' });
  // }

  if (store.state.currentUser) {
    next();
  } else {
    next({ name: 'Home' });
  }
};

const requireAuthenticationButCantHaveChild = (
  to: Route, from: Route, next: NavigationGuardNext<Vue>,
) => {
  // if (NODE_ENV === 'staging') {
  //   const inviteCookie = Vue.$cookies.get(INVITE_COOKIE_NAME) as string;
  //   if (!inviteCookie) next({ name: 'Invite' });
  // }

  if (store.state.currentUser && !store.state.child) {
    next();
  } else {
    next({ name: 'NoChild' });
  }
};

const requireAuthenticationAndChild = (
  to: Route, from: Route, next: NavigationGuardNext<Vue>,
) => {
  // if (NODE_ENV === 'staging') {
  //   const inviteCookie = Vue.$cookies.get(INVITE_COOKIE_NAME) as string;
  //   if (!inviteCookie) next({ name: 'Invite' });
  // }

  if (store.state.currentUser && store.state.child) {
    next();
  } else {
    next({ name: 'RegisterChild' });
  }
};

const routes: Array<RouteConfig> = [
  // {
  //   path: '/invite',
  //   name: 'Invite',
  //   component: InvitePage,
  //   beforeEnter(to, from, next) {
  //     if (NODE_ENV === 'production' || NODE_ENV === 'test') {
  //       next({ name: 'Home' });
  //     }
  //     const inviteCookie = Vue.$cookies.get(INVITE_COOKIE_NAME) as string;
  //     if (inviteCookie === INVITE_COOKIE_SECRET) {
  //       next({ name: 'Home' });
  //     } else {
  //       next();
  //     }
  //   },
  // },
  {
    path: '/',
    name: 'Home',
    component: StartPage,
    beforeEnter(to, from, next) {
      if (to.query.i === INVITE_COOKIE_CODE) {
        Vue.$cookies.set(INVITE_COOKIE_NAME, INVITE_COOKIE_SECRET, Infinity);
      }

      // if (NODE_ENV === 'staging') { //Används bara om vi ska begränsa access
      //   const inviteCookie = Vue.$cookies.get(INVITE_COOKIE_NAME);
      //   if (inviteCookie !== INVITE_COOKIE_SECRET) next({ name: 'Invite' });
      // }

      if (store.state.currentUser) {
        next({ name: 'Overview' });
      } else {
        next();
      }
    },
  },
  {
    path: '/tavling',
    name: 'competition',
    component: CompetitionPage,
    beforeEnter(to, from, next) {
      if (store.state.currentUser) {
        next({ name: 'Overview' });
      } else {
        next();
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter(to, from, next) {
      if (store.state.currentUser) {
        next({ name: 'Overview' });
      } else {
        next();
      }
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    beforeEnter(to, from, next) {
      if (store.state.registrationData) {
        next();
      } else {
        next({ name: 'Home' });
      }
    },
  },
  {
    path: '/profile/edit',
    name: 'Redigera profil',
    component: Register,
    beforeEnter: requireAuthentication,
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
    beforeEnter: requireAuthentication,
  },
  {
    path: '/addedchild',
    name: 'AddedChild',
    component: AddedChild,
    beforeEnter: requireAuthentication,
  },
  {
    path: '/nochild',
    name: 'NoChild',
    component: NoChild,
    props: (route) => ({
      childName: '' as string,
      ...route.params,
    }),
    beforeEnter: requireAuthenticationButCantHaveChild,
  },
  {
    path: '/registerchild',
    name: 'RegisterChild',
    component: RegisterChild,
    beforeEnter: requireAuthenticationButCantHaveChild,
  },
  {
    path: '/child/edit',
    name: 'Redigera barn',
    component: RegisterChild,
    beforeEnter: requireAuthenticationAndChild,
  },
  {
    path: '/child/createpurchase',
    name: 'Create purchase',
    component: CreatePurchase,
    beforeEnter: requireAuthentication,
  },
  {
    path: '/child/viewpurchase/:purchaseId',
    name: 'View purchase',
    component: ViewPurchase,
    beforeEnter: requireAuthentication,
  },
  {
    path: '/child/splitpurchases',
    name: 'SplitPurchases',
    component: SplitPurchases,
    beforeEnter: requireAuthentication,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter(to, from, next) {
      if (store.state.currentUser && to.query.access && to.query.access === 'lfant') {
        next();
      } else {
        next({ name: 'Home' });
      }
    },
  },
  {
    path: '/cookies',
    name: 'Cookie-Information',
    component: CookieView,
  },
  {
    path: '/competitionterms',
    name: 'Competition-terms',
    component: CompetitionTerms,
  },
  {
    path: '/tos',
    name: 'Terms-information',
    component: TermsView,
  },
  {
    path: '/pp',
    name: 'Privacy-information',
    component: TermsView,
  },
  {
    path: '/connected',
    name: 'ConnectResult',
    component: ConnectResult,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
