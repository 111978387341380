

















































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
// @ts-ignore
import { MdDialog } from 'vue-material/dist/components';
import { ACCOUNT_URL, IMAGE_URL } from '../environment';
import { validateEmail, validateName } from '../utils/validators';
import { postImage } from '../utils/helpers';
import InputEvent from '../models/HTMLInputEvent';
import PrimaryButton from '../components/PrimaryButton.vue';
import InputField from '../components/InputField.vue';
import PrimaryCheckbox from '../components/PrimaryCheckbox.vue';
import ImageUpload from '../components/ImageUpload.vue';
import RegisterHeader from '../components/RegisterHeader.vue';
import ConnectResult from '../components/ConnectResult.vue';
import { localStorageKeys } from '../utils/constants';

Vue.use(MdDialog);

export default Vue.extend({
  name: 'Register',
  components: {
    PrimaryButton,
    InputField,
    ImageUpload,
    RegisterHeader,
    ConnectResult,
    PrimaryCheckbox,
  },
  data: () => ({
    socialSecurityNr: '' as string,
    name: '' as string,
    email: '' as string,
    mobile: '' as string,
    allowEmail: false as boolean,
    allowSms: true as boolean,
    autoApprove: true as boolean,
    autoApproveAmount: 300 as number,
    profileImage: null,
    validEmail: true as boolean,
    validMobile: true as boolean,
    validName: true as boolean,
    validAutoApprove: true as boolean,
    avatarImage: null,
    userImage: '' as string,
    emailErrorMSG: 'Fyll i en giltig email.' as string,
    mobileErrorMSG: 'Fyll i ett giltigt mobilnummer.' as string,
    nameErrorMSG: 'Fyll i ett namn.' as string,
    hasImage: false as boolean,
    showModal: false as boolean,
    childName: 'barnet' as string,
    inviteToken: '' as string,
    reference: '' as string,
  }),
  computed: {
    ...mapGetters({ user: 'getCurrentUser' }),
    ...mapGetters({ child: 'getChild' }),
    ...mapGetters({ responder: 'getResponder' }),
    ...mapGetters({ registrationData: 'getRegistrationData' }),
  },
  methods: {
    async saveOrUpdateProfile() {
      this.validateEmail();
      this.validateMobile();
      this.validateName();
      this.validateAutoApprove();
      if (this.validEmail
        && this.validMobile
        && this.validName
        && this.validAutoApprove) {
        const fData = new FormData();
        // @ts-ignore
        if (this.profileImage) fData.append('file', this.profileImage);
        let imgName = '';
        if (this.hasImage) {
          imgName = this.user.imageName;
        } else if (this.profileImage) {
          imgName = await postImage(fData);
        }
        if (this.inviteToken) {
          this.reference = 'invited';
        }
        const data = {
          name: this.name,
          socialSecurityNr: this.socialSecurityNr,
          autoApprove: this.autoApprove,
          autoApproveAmount: this.autoApproveAmount,
          allowEmail: this.allowEmail,
          allowSms: this.allowSms,
          email: this.email,
          mobile: this.mobile,
          imageName: imgName,
          inviteCode: this.inviteToken,
          reference: this.reference,
        };
        if (this.user) {
          this.$http
            .patch(`${ACCOUNT_URL}edit`, data)
            .then((response) => {
              if (response.status === 200) {
                this.$store.commit('setCurrentUser', response.data);
                if (this.child) this.childUpdate();
                this.$router.replace({
                  name: 'Overview',
                });
              }
            })
            .catch((error: any) => console.log(error));
        } else {
          const response = await this.$http
            .post(ACCOUNT_URL, data)
            .then((reply) => reply)
            .catch((error) => error.response);
          if (response.status === 201) {
            const { account, childName } = response.data;
            this.$store.commit('setCurrentUser', account);
            if (childName) {
              // this.$router.push({
              //   name: 'AddedChild',
              // });
              // this.$router.replace({
              //   name: 'ConnectResult',
              // });
              this.showModal = true;
            } else if (!childName) {
              this.showModal = true;
            }
          }
          if (response.status === 409) {
            this.emailErrorMSG = 'Epostadressen finns redan registrerad';
            this.validEmail = false;
          }
        }
      }
    },
    childUpdate() {
      const childUpdate = this.child;
      if (this.child.parent1 === this.responder) {
        childUpdate.parent2 = this.user.name;
      } else {
        childUpdate.parent1 = this.user.name;
      }
      this.$store.commit('setChild', childUpdate);
    },
    clickedImageUpload() {
      if (this.user
        && this.user.imageName) {
        this.hasImage = false;
      }
    },
    setImage(file: Blob) {
      // @ts-ignore
      this.profileImage = (file === null)
        ? null
        : file;
    },
    validateAndUpload(e: InputEvent) {
      e.preventDefault();
      this.profileImage = (e
        && e.target
        && e.target.files
        && e.target.files[0]
        ? e.target.files[0] : null as any);
    },
    changeEmail() {
      this.allowEmail = !this.allowEmail;
    },
    changeSms() {
      this.allowSms = !this.allowSms;
    },
    validateEmail() {
      this.validEmail = validateEmail(this.email);
    },
    validateMobile() {
      this.validMobile = /((((0{2}?)|(\+){1})46)|0)7[\d]{8}$/.test(this.mobile);
    },
    validateName() {
      this.validName = validateName(this.name);
    },
    validateAutoApprove() {
      this.validAutoApprove = (this.autoApproveAmount >= 0);
    },
  },
  created() {
    if (this.user) {
      this.socialSecurityNr = this.user.socialSecurityNr;
      this.name = this.user.name;
      this.email = this.user.email;
      this.mobile = this.user.mobile;
      this.userImage = this.user.imageName
        ? `${IMAGE_URL}${this.user.imageName}`
        : '';
      this.autoApprove = this.user.autoApprove;
      this.autoApproveAmount = this.user.autoApproveAmount;
      this.hasImage = (this.user.imageName);
      this.allowEmail = this.user.allowEmail;
    } else {
      this.name = this.registrationData.fullName;
      this.socialSecurityNr = this.registrationData.ssn;
    }
  },
  mounted() {
    try {
      const inviteToken = localStorage.getItem(localStorageKeys.inviteToken);
      if (inviteToken) {
        this.inviteToken = inviteToken;
      }
    } catch {}
    try {
      const reference = localStorage.getItem(localStorageKeys.reference);
      if (reference) {
        this.reference = reference;
      }
    } catch {}
  },
  beforeDestroy() {
    this.$store.commit('resetRegistrationData');
  },
});
