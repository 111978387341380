import { render, staticRenderFns } from "./RegisterHeader.vue?vue&type=template&id=186b3ed2&scoped=true&functional=true&"
import script from "./RegisterHeader.vue?vue&type=script&lang=ts&"
export * from "./RegisterHeader.vue?vue&type=script&lang=ts&"
import style0 from "./RegisterHeader.vue?vue&type=style&index=0&id=186b3ed2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "186b3ed2",
  null
  
)

export default component.exports