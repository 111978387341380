import validator from 'validator';
import Personnummer from 'personnummer';

const imgFileTypes = [
  'image/png',
  'image/jpeg',
];

export const validateEmail = (email: string): boolean => validator.isEmail(email);
export const validateName = (name: string): boolean => (name.length > 0);
export const validateSocialSecurityNumber = (ssn: string): boolean => Personnummer.valid(ssn);
export const validateImgFileType = (file: File): boolean => imgFileTypes.includes(file.type);
export const validatePrice = (price: number): boolean => (price > 0);
