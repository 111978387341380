














































import Vue from 'vue';

// @ts-ignore
import { MdDialog } from 'vue-material/dist/components';
import { delay } from '../utils/handler';
import { validateImgFileType } from '../utils/validators';
import InputEvent from '../models/HTMLInputEvent';
import SecondaryButton from './SecondaryButton.vue';
import Snackbar from './Snackbar.vue';
import ImageCropper from './ImageCropper.vue';

Vue.use(MdDialog);

export default Vue.extend({
  name: 'ImageUpload',
  components: {
    SecondaryButton,
    Snackbar,
    ImageCropper,
  },
  data: () => ({
    buttonIcon: 'add' as string,
    file: {} as Blob,
    fileUrl: '' as string,
    hasMessage: false as boolean,
    errorMessage: 'Bilden måste vara PNG eller JPEG' as string,
    showDialog: false as boolean,
    cropImg: null,
    hasUserImage: false as boolean,
    hasOriginalPicture: false as boolean,
  }),
  props: {
    disabled: Boolean,
    img: String,
    avatar: Boolean,
    userImage: String,
    uploadButtonText: String,
  },
  computed: {
    renderUrl() {
      if (this.userImage && this.hasOriginalPicture) {
        return this.userImage;
      }
      if (this.fileUrl.length > 0) {
        return this.fileUrl;
      }
      return '';
    },
    avatarClassNames() {
      let str = '';
      if (this.avatar) str += 'avatar ';
      if (!this.hasUserImage) str += 'd-none ';
      return str;
    },
  },
  methods: {
    setFile(e: InputEvent): void {
      if (e
        && e.target
        && e.target.files
        && e.target.files[0]) {
        if (validateImgFileType(e.target.files[0]) && this.avatar) {
          this.showDialog = true;
          // @ts-ignore
          this.cropImg = URL.createObjectURL(e.target.files[0]);
        } else if (validateImgFileType(e.target.files[0])) {
          this.file = e.target.files[0];
          this.setComponentState();
          this.saveFile(this.file);
        }
      } else {
        e.target.value = '';
        this.showSnackbar();
      }
      this.$emit('change');
    },
    setImagePreview(): void {
      const el = document.getElementById('uploadedImage');
      if (el) {
        if (this.file) {
          if (el.classList.contains('d-none')) {
            el.classList.remove('d-none');
            this.fileUrl = URL.createObjectURL(this.file);
          }
        } else {
          URL.revokeObjectURL(this.fileUrl);
          el.classList.add('d-none');
          this.hasUserImage = false;
        }
      }
    },
    add() {
      const el = document.getElementById('fileInput') as HTMLInputElement;
      el.click();
    },
    remove() {
      this.hasOriginalPicture = false;
      this.hasUserImage = false;
      // @ts-ignore
      this.file = null;
      const el = document.getElementById('fileInput') as HTMLInputElement;
      el.value = '';
      this.setComponentState();
      this.$emit('click');
    },
    showSnackbar(): void {
      this.hasMessage = true;
      delay(4000)
        .then(() => {
          this.hasMessage = false;
        });
    },
    clearFile(): void {
      this.showDialog = false;
      const el = document.getElementById('fileInput') as HTMLInputElement;
      el.value = '';
      // @ts-ignore
      this.file = null;
      this.setComponentState();
    },
    saveFile(file: Blob): void {
      this.showDialog = false;
      this.file = file;
      this.fileUrl = URL.createObjectURL(this.file);
      this.hasUserImage = true;
      this.setImagePreview();
      this.setComponentState();
    },
    setComponentState(): void {
      this.setImagePreview();
      this.$emit('selectedImage', this.file);
    },
  },
  mounted() {
    if (this.userImage) {
      this.hasOriginalPicture = true;
      this.hasUserImage = true;
    }
  },
});
