




















































































































import Vue from 'vue';
// @ts-ignore
import { MdIcon } from 'vue-material/dist/components';
import PrimaryButton from './PrimaryButton.vue';
import SecondaryButton from './SecondaryButton.vue';

Vue.use(MdIcon);

export default Vue.extend({
  name: 'CookieConsent',
  components: {
    PrimaryButton,
    SecondaryButton,
  },
  data: () => ({
    performanceAccordion: false as boolean,
    necessaryAccordion: false as boolean,
    settingsToggle: false as boolean,
    performanceCookiesAccepted: false as boolean,
  }),
  props: {
    saveAll: Function,
    saveSome: Function,
  },
  methods: {
    goBack(): void {
      this.$router.push({ name: 'Overview' });
    },
    togglePerformanceAccordion(): void {
      this.performanceAccordion = !this.performanceAccordion;
      this.scrollIntoView();
    },
    toggleNecessaryAccordion(): void {
      this.necessaryAccordion = !this.necessaryAccordion;
      this.scrollIntoView();
    },
    toggleSettings(): void {
      this.settingsToggle = !this.settingsToggle;
      this.scrollIntoView();
    },
    saveCookies(): void {
      if (this.performanceCookiesAccepted) {
        this.saveAll();
        return;
      }
      this.saveSome();
    },
    scrollIntoView(): void {
      if (!this.settingsToggle) {
        return;
      }
      const element = document.getElementById('allowsome');
      if (!element) {
        return;
      }
      element.scrollIntoView({ behavior: 'smooth' });
    },
    goToCookies(): void {
      this.$router.push({ name: 'Cookie-Information' });
    },
    goToCookieInformation(): void {
      this.$router.push({
        name: 'Cookie-Information',
        query: { scrolltocookies: 'true' },
      });
    },
  },
});

