
























import Vue from 'vue';

// @ts-ignore
import { MdField, MdInput } from 'vue-material/dist/components';

Vue.use(MdField, MdInput);

export default Vue.extend({
  name: 'InputField',
  props: {
    value: String,
    type: String,
    disabled: Boolean,
    placeholder: String,
    required: Boolean,
    label: String,
    helper: String,
    hasMessage: Boolean,
    errorMessage: String,
  },
  computed: {
    inputValue: {
      get() {
        // @ts-ignore
        return `${this.value}`;
      },
      set(val) {
        // @ts-ignore
        this.$emit('input', val);
      },
    },
    messageClass() {
      return {
        'md-invalid': this.hasMessage,
      };
    },
  },
});
