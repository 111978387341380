

























import Vue from 'vue';

export default Vue.extend({
  name: 'DashboardHeader',
  props: [
    'img',
    'headerText',
    'secondParent',
    'isPurchase',
  ],
});
