

























import Vue from 'vue';
import { mapGetters } from 'vuex';
// @ts-ignore
import { MdDialog } from 'vue-material/dist/components';
import { POPUP_COOKIE_INFO_NAME } from './environment';
import NavigationDrawer from './components/NavigationDrawer.vue';
import CookieConsent from './components/CookieConsent.vue';
import { localStorageKeys } from './utils/constants';

Vue.use(MdDialog);

export default Vue.extend({
  name: 'App',
  components: {
    NavigationDrawer,
    CookieConsent,
  },
  data: () => ({
    necessaryCookies: false as boolean,
  }),
  computed: {
    ...mapGetters({ user: 'getCurrentUser' }),
    showCookiePopup(): boolean {
      return !this.$cookies.isKey(POPUP_COOKIE_INFO_NAME);
    },
    shouldShowNavBar() {
      return this.$route.name !== 'ConnectResult';
    },
  },
  methods: {
    cookieConsentAll(): void {
      localStorage.setItem(localStorageKeys.performanceCookies, 'accepted');
      this.$gtm.enable(true);
      // @ts-ignore
      this.cookieConsentNecessary();
    },
    cookieConsentNecessary(): void {
      localStorage.setItem(localStorageKeys.necessaryCookies, 'accepted');
      this.necessaryCookies = true;
    },
    checkNecessaryCookie() {
      try {
        const necessaryCookies = localStorage.getItem(localStorageKeys.necessaryCookies);
        if (necessaryCookies) {
          this.necessaryCookies = true;
          return;
        }
        this.necessaryCookies = false;
      } catch {}
    },
  },
  watch: {
    $route() {
      // @ts-ignore
      if (this.$route.name === 'Home') this.checkNecessaryCookie();
      if (this.$route.name === 'Cookie-Information') this.necessaryCookies = true;
    },
  },
  mounted() {
    // @ts-ignore
    this.checkNecessaryCookie();
    try {
      const performanceCookies = localStorage.getItem(localStorageKeys.performanceCookies);
      if (performanceCookies) {
        this.$nextTick(() => this.$gtm.enable(true));
      }
    } catch {}
  },
});

