

















































import Vue from 'vue';
// @ts-ignore
import { MdDialog } from 'vue-material/dist/components';
import { ACCOUNT_URL } from '../environment';
import ConnectResult from '../components/ConnectResult.vue';
import PrimaryButton from '../components/PrimaryButton.vue';
import SecondaryButton from '../components/SecondaryButton.vue';
import InputField from '../components/InputField.vue';

Vue.use(MdDialog);

export default Vue.extend({
  name: 'NoChild',
  components: {
    PrimaryButton,
    SecondaryButton,
    InputField,
    ConnectResult,
  },
  data: () => ({
    codeBoxIsOpen: true,
    code: '',
    childName: 'barnet',
    hasError: false,
    showModal: false,
    errorMessage: '',
  }),
  methods: {
    sendToRegisterChild() {
      this.$router.push({ name: 'RegisterChild' });
    },
    validateCode() {
      const codeErrorMessage = 'En giltig kod består av 4 tecken av a-z eller 0-9';
      if (this.code.match('^[A-Za-z0-9]{4}$')) {
        this.hasError = false;
        this.errorMessage = '';
        return;
      }
      this.hasError = true;
      this.errorMessage = codeErrorMessage;
    },
    openCodeBoxOrConnect() {
      if (!this.codeBoxIsOpen) {
        this.codeBoxIsOpen = true;
        return;
      }
      this.validateCode();
      if (!this.hasError) this.connectParent();
    },
    connectParent() {
      const payload = {
        inviteCode: this.code,
      };
      this.$http
        .post(`${ACCOUNT_URL}connect`, payload)
        .then((response: any) => {
          console.log(response);
          if (response.status === 200) {
            if (response.data && response.data.name) this.childName = response.data.name;
            this.showModal = true;
          } else {
            console.log('Här borde vi inte hamna om vi lyckats tror jag');
          }
        })
        .catch((error: any) => {
          console.log(error);
          this.hasError = true;
          this.errorMessage = 'Något gick fel, kolla gärna så du skrivit rätt eller kontakta supporten';
        });
    },
  },
});
