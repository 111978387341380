function hintCodeToUserError(hintCode: string) {
  switch (hintCode) {
    case 'outstandingTransaction':
    case 'noClient':
      return 'Starta BankID-appen';
    case 'userCancel':
      return 'Åtgärden avbruten';
    case 'expiredTransaction':
      return `BankID-appen svarar inte. Kontrollera att den är startad och att du har 
          internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos 
          din Bank. Försök sedan igen.`;
    case 'userSign':
      return 'Skriv in din säkerhetskod i BankID-appen och välj Legitimera eller '
          + 'Skriv under.';
    default:
      return 'Okänt fel. Försök igen';
  }
}

function errorCodeToUserError(errorCode: string) {
  switch (errorCode) {
    case 'cancelled':
      return 'Åtgärden avbruten. Försök igen.';
    case 'alreadyInProgress':
      return 'En identifiering eller underskrift för det här '
        + 'personnumret är redan påbörjad. Försök igen.';
    case 'requestTimeout':
    case 'maintenance':
    case 'internalError':
      return 'Internt tekniskt fel. Försök igen.';
    default:
      return 'Okänt fel. Försök igen.';
  }
}

export function userError(response: any) {
  if (response.hintCode) {
    return hintCodeToUserError(response.hintCode);
  }

  if (response.errorCode) {
    return errorCodeToUserError(response.errorCode);
  }

  if (response.code) {
    if (response.code === 'alreadyInProgress') return 'Inloggning redan påbörjad, vänta en stund och försök därefter igen.';
  }

  return 'Okänt fel. Försök igen.';
}
