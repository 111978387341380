


































































































































import Vue from 'vue';
// @ts-ignore
import { mapGetters } from 'vuex';
import { validateName, validatePrice } from '../utils/validators';
import { IMAGE_URL, PURCHASE_EDIT_URL, PURCHASE_URL } from '../environment';
import { postImage, checkURLForHttp, dataURLToBlob } from '../utils/helpers';
import EditDialog from '../components/EditDialog.vue';
import RemoveConfirm from '../components/RemoveConfirm.vue';
import InputField from '../components/InputField.vue';
import ImageUpload from '../components/ImageUpload.vue';
import PrimaryButton from '../components/PrimaryButton.vue';
import InputTextarea from '../components/InputTextarea.vue';
import PercentDivider from '../components/PercentDivider.vue';
import SecondaryButton from '../components/SecondaryButton.vue';

export default Vue.extend({
  name: 'CreatePurchase',
  components: {
    EditDialog,
    RemoveConfirm,
    InputField,
    ImageUpload,
    PrimaryButton,
    InputTextarea,
    PercentDivider,
    SecondaryButton,
  },
  data: () => ({
    purchaseId: '' as string,
    editMode: false as boolean,
    productTitle: '',
    productPrice: null as unknown as number,
    productDescription: '',
    productURL: '',
    validTitle: true,
    titleErrorMSG: 'Du måste fylla i ett produkt namn.',
    validPrice: true,
    priceErrorMSG: 'Du måste fylla i ett pris.',
    childImg: '',
    createdByPercentPart: 0,
    isPosting: false,
    completed: false,
    alreadyBought: false,
    productImageBlob: null as Blob | null,
    productImageName: '' as string,
    productImageUrl: '' as string,
    hasProductImage: false as boolean,
    loading: false,
    done: false,
    showConfirmRemovalModal: false,
    mode: '' as string,
  }),
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
      child: 'getChild',
      responder: 'getResponder',
    }),
    parent() {
      return (this.child.parent1 === this.user.name
              || this.child.parent1 === this.user.socialSecurityNr)
        ? this.child.parent2
        : this.child.parent1;
    },
  },
  methods: {
    async editPurchase() {
      // @ts-ignore
      this.validateTitle();
      // @ts-ignore
      this.validatePrice();
      if (!this.validTitle && !this.validPrice) return;
      this.isPosting = true;
      const fData = new FormData();
      // @ts-ignore
      if (this.productImageBlob && !this.hasProductImage) {
        fData.append('file', this.productImageBlob);
      }
      let imgName = '';
      if (this.hasProductImage) {
        imgName = this.productImageName;
      } else if (this.productImageBlob) {
        imgName = await postImage(fData);
      }

      const data = {
        purchaseId: this.purchaseId as string,
        name: this.productTitle as string,
        description: this.productDescription as string,
        price: this.productPrice as number,
        link: this.productURL.length > 0 ? checkURLForHttp(this.productURL) : '' as string,
        imageName: imgName as string,
        createdByPercentPart: this.createdByPercentPart,
      };

      this.$http
        .patch(PURCHASE_EDIT_URL, data)
        .then()
        .catch((error: any) => {
          console.log(error.response);
        })
        .finally(() => {
          this.isPosting = false;
          this.mode = 'edit';
          this.done = true;
        });
    },
    async postPurchase() {
      // @ts-ignore
      this.validateTitle();
      // @ts-ignore
      this.validatePrice();
      if (this.validTitle && this.validPrice) {
        // @ts-ignore
        const fData = new FormData();
        if (this.productImageBlob) {
        // @ts-ignore
          fData.append('file', this.productImageBlob);
        }
        const data = {
          childId: this.child.publicId as string,
          createdBy: this.user.publicId as string,
          name: this.productTitle as string,
          description: this.productDescription as string,
          completed: this.completed as boolean,
          price: this.productPrice as number,
          link: this.productURL.length > 0 ? checkURLForHttp(this.productURL) : '',
          imageName: this.productImageBlob ? await postImage(fData) : '',
          createdByPercentPart: this.createdByPercentPart,
        };
        this.isPosting = true;
        this.$http
          .post(PURCHASE_URL, data)
          .then((res) => {
            const { message } = res.data;
            this.mode = message;
          })
          .catch((error: any) => {
            console.log(error.response);
          })
          .finally(() => {
            this.isPosting = false;
            this.done = true;
          });
      }
    },
    setPercent(createdByPercent: number) {
      this.createdByPercentPart = createdByPercent;
    },
    validateTitle() {
      this.validTitle = validateName(this.productTitle);
    },
    validatePrice() {
      this.validPrice = validatePrice(this.productPrice);
    },
    hasHistory(): boolean {
      return window.history.length > 2;
    },
    async resizeImage(): Promise<void> {
      const file = this.productImageBlob;

      const reader = new FileReader();
      // @ts-ignore
      reader.onload = (readerEvent: Record<string, any>): Function|null => {
        const image = new Image();
        // @ts-ignore
        image.onload = (): Function|null => {
          const canvas = document.createElement('canvas');
          const maxSize = 800;
          let { width } = image; let
            { height } = image;
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
          canvas.width = width;
          canvas.height = height;
          // @ts-ignore
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL('image/jpeg');
          const resizedImage = dataURLToBlob(dataUrl);
          this.productImageBlob = resizedImage;
        }; // @ts-ignore
        image.src = readerEvent.target.result;
      };
      // @ts-ignore
      reader.readAsDataURL(file);
    },
    fetchOnePurchase(purchaseId: string) {
      this.editMode = true;
      const url = `${PURCHASE_URL}${this.child.publicId}/${purchaseId}`;
      this.$http.get(url)
        .then((res: any) => {
          const item = res.data;
          this.productTitle = item.name;
          this.productPrice = item.price;
          this.productDescription = item.description;
          this.productURL = item.link;
          this.completed = item.completed;
          this.createdByPercentPart = item.createdByPercentPart;
          this.productImageName = item.imageName;
          this.productImageUrl = item.imageName
            ? `${IMAGE_URL}${item.imageName}`
            : '';
          this.hasProductImage = (item.imageName);
          this.loading = false;
        })
        .catch((error: any) => {
          if (error.response.status === 404) this.$router.go(-1);
          else console.log(error);
        });
    },
    clickedImageUpload() {
      if (this.editMode && this.productImageUrl) {
        this.hasProductImage = false;
      }
    },
    async setImage(blob: Blob) {
      if (!blob) this.productImageBlob = null;
      else {
        this.productImageBlob = blob;
        // @ts-ignore
        await this.resizeImage();
      }
    },
    async removePurchase() {
      this.$http
        .delete(`${PURCHASE_URL}${this.purchaseId}`)
        .then((res) => {
          if (res.status === 200) {
            this.$router.push({ name: 'Overview' });
          }
        })
        .catch((error) => {
          console.log('HTTP STATUS: ', error.response.status);
          this.$router.push({ name: 'Overview' });
        });
    },
    remove() {
      this.showConfirmRemovalModal = true;
    },
  },
  mounted() {
    if (this.$route.params.purchaseId) {
      this.purchaseId = this.$route.params.purchaseId;
      this.loading = true;
      this.$nextTick(() => {
        // @ts-ignore
        this.fetchOnePurchase(this.$route.params.purchaseId);
      });
    }

    if (this.child.imageName) {
      this.childImg = `${IMAGE_URL}${this.child.imageName}`;
    }
    this.createdByPercentPart = (this.child.parent1.publicId === this.user.publicId)
      ? this.child.parent1PercentPart
      : (100 - this.child.parent1PercentPart);
  },
});
