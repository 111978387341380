



















































import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    goBackText: '< Till tävlingssidan.' as string,
  }),
  methods: {
    goToCompetitionPage() {
      this.$router.push({ name: 'competition' });
    },
  },
});
