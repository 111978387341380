





























































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
// @ts-ignore
import { MdDialog } from 'vue-material/dist/components';
import { CHILD_URL, IMAGE_URL } from '../environment';
import { validateEmail, validateName } from '../utils/validators';
import { postImage } from '../utils/helpers';
import PrimaryButton from '../components/PrimaryButton.vue';
import InputField from '../components/InputField.vue';
import ImageUpload from '../components/ImageUpload.vue';
import PercentDivider from '../components/PercentDivider.vue';
import ConnectResult from '../components/ConnectResult.vue';

Vue.use(MdDialog);

export default Vue.extend({
  name: 'RegisterChild',
  components: {
    PrimaryButton,
    InputField,
    ImageUpload,
    PercentDivider,
    ConnectResult,
  },
  computed: {
    ...mapGetters({ user: 'getCurrentUser', responder: 'getResponder', child: 'getChild' }),
  },
  data: () => ({
    name: '',
    parent2Name: '',
    parent1: '',
    parent2: '',
    email: '',
    validEmail: true,
    validName: true,
    validParentName: true,
    profileImage: null,
    avatarImage: '',
    emailErrorMSG: 'Fyll i en giltig email.',
    nameErrorMSG: 'Fyll i ett namn.',
    apiErrorMessage: '',
    hasRemovedImage: false,
    parent1PercentPart: 50,
    createdByPercentPart: 50,
    canRegister: true,
    showModal: false,
    shouldAddParent: true,
  }),
  created() {
    this.parent1 = this.user.publicId;
    if (this.child) {
      this.name = this.child.name;
      this.avatarImage = this.child.imageName
        ? `${IMAGE_URL}${this.child.imageName}`
        : '';
    }
  },
  methods: {
    async saveOrUpdateChildProfile() {
      this.validateEmail();
      this.validateName();
      this.validateParentName();
      let imgName: string;
      const fData = new FormData();

      if (!this.child
        && (!this.shouldAddParent || this.validEmail)
        && this.validName
        && this.canRegister) {
        // @ts-ignore
        fData.append('file', this.profileImage);
        this.canRegister = false;

        const data = {
          name: this.name,
          parent2: this.parent2Name,
          email: this.shouldAddParent ? this.email : '',
          // @ts-ignore
          imageName: this.profileImage ? await postImage(fData) : '',
          parent1PercentPart: this.parent1PercentPart,
        };
        this.$http.post(CHILD_URL, data)
          .then((response) => {
            if (response.status === 201) {
              this.showModal = true;
            }
          })
          .catch(() => {
            this.apiErrorMessage = 'Något gick fel';
            this.canRegister = true;
          });
      } else {
        if (this.profileImage) {
          // @ts-ignore
          fData.append('file', this.profileImage);
          imgName = this.profileImage ? await postImage(fData) : '';
        }
        // @ts-ignore
        if (!imgName && !this.hasRemovedImage) {
          imgName = this.child.imageName;
        }
        const data = {
          name: this.name,
          // @ts-ignore
          imageName: imgName,
          parent1PercentPart: (this.user.publicId === this.child.parent1.publicId)
            ? this.parent1PercentPart : 100 - this.parent1PercentPart,
        };
        this.$http.patch(`${CHILD_URL}edit/${this.child.publicId}`, data)
          .then(((response) => {
            if (response.status === 200) {
              this.$router.replace({ name: 'Overview', params: { socialSecurityNr: this.parent1 } });
            }
          }))
          .catch((error) => {
            if (error.response.status === 400) {
              this.apiErrorMessage = 'Något gick fel';
            }
          });
      }
    },
    setImage(file: Blob) {
      // @ts-ignore
      this.profileImage = file;
    },
    validateEmail() {
      this.validEmail = validateEmail(this.email);
    },
    validateName() {
      this.validName = validateName(this.name);
    },
    validateParentName() {
      this.validParentName = validateName(this.parent2Name);
    },
    clickedImageUpload() {
      if (this.user && this.user.imageName) {
        this.hasRemovedImage = true;
      }
    },
    setPercent(createdByPercent: number) {
      // @ts-ignore
      this.parent1PercentPart = createdByPercent;
    },
  },
});
