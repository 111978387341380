
































































































































import Vue from 'vue';
// @ts-ignore
import { MdDialog, MdButton } from 'vue-material/dist/components';
import { validateSocialSecurityNumber } from '../utils/validators';
import { userError } from '../utils/bankidUserError';
import { BANK_ID_URL, ACCOUNT_URL, PHONE_DESIGN_ACCEPT } from '../environment';
import PrimaryButton from '../components/PrimaryButton.vue';
import BankidDialog from '../components/BankidDialog.vue';
import PrimaryCheckbox from '../components/PrimaryCheckbox.vue';
import InputField from '../components/InputField.vue';
import { localStorageKeys } from '../utils/constants';
import { toTitleCase, delay } from '../utils/helpers';

Vue.use(MdDialog, MdButton);

export default Vue.extend({
  name: 'Login',
  components: {
    PrimaryButton,
    PrimaryCheckbox,
    InputField,
    BankidDialog,
  },
  data: () => ({
    socialSecurityNr: '' as string,
    saveSocialTick: false,
    terms: {
      conditions: {
        accepted: false,
        text: 'Jag har läst och godkänner',
        link: {
          path: '/tos',
          text: 'Familjefix allmänna villkor.',
        },
      },
      privacyPolicy: {
        accepted: false,
        text: 'Jag har läst och godkänner hur',
        link: {
          path: '/pp',
          text: 'Familjefix nyttjar användardata.',
        },
      },
    },
    acceptPrivacyPolicy: false,
    validSSN: true,
    showPhoneDesign: '' as any,
    showBankid: false,
    SSNErrorMSG: 'Personummer behöver vara 12 siffror. ååååMMddnnnn' as string,
    bankidErrorMessage: '' as string,
    inviteToken: '' as any,
    reference: '' as any,
    autostarttoken: '' as string,
    orderRef: '' as string,
  }),
  computed: {
    isValidSsn(): boolean {
      return (validateSocialSecurityNumber(this.socialSecurityNr)
        && this.socialSecurityNr.length === 12);
    },
  },
  methods: {
    goToStartPage() {
      this.$router.push({ name: 'Home' });
    },
    async collect() {
      if (this.showBankid) {
        this.$http
          .get(`${BANK_ID_URL}${this.orderRef}`)
          .then((response: any) => {
            if (response.status === 200) {
              this.saveSSN();
              this.saveTerms();
              this.setPhoneDesignAccepted();
              this.registerOrLogin(response.data);
            }
          }).catch(async (error: any) => {
            if (error.response.status === 404) {
              await delay(500);
              return this.collect();
            }
            if (error.response) this.bankidErrorMessage = userError(error.response.data);
            this.showBankid = false;
            return null;
          });
      }
    },
    async login() {
      this.bankidErrorMessage = '';
      const data = { socialSecurityNr: this.socialSecurityNr };
      this.$http
        .post(BANK_ID_URL, data)
        .then((response: any) => {
          this.autostarttoken = response.data.autostarttoken;
          this.orderRef = response.data.ref;
          this.collect();
        }).catch((error: any) => {
          if (error.response) this.bankidErrorMessage = userError(error.response.data);
          this.showBankid = false;
        });
    },
    cancel() {
      this.showBankid = false;
    },
    async registerOrLogin(data: any) {
      if (data.publicId) {
        const url = ACCOUNT_URL;
        this.$http
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit('setCurrentUser', response.data);
              this.$router.push({ name: 'Overview' });
            }
          });
      } else {
        this.$store.commit('setRegistrationData',
          {
            ssn: this.socialSecurityNr,
            fullName: toTitleCase(data.givenName.split(' ')[0]),
          });
        this.setRegistrationData();
        this.$router.replace({
          name: 'Register',
        });
      }
    },
    startLogin() {
      this.validateSSN();
      if (this.validSSN && this.terms.privacyPolicy.accepted
        && this.terms.conditions.accepted
        && this.isValidSsn) {
        // const payload = {
        //   ref: this.orderRef,
        //   ssn: this.socialSecurityNr,
        //   save: this.saveSocialTick,
        // };
        // localStorage.setItem(loginRef.data, JSON.stringify(payload));
        this.showBankid = true;
        this.login();
      }
    },
    validateSSN() {
      this.validSSN = (this.socialSecurityNr.length === 12
        && validateSocialSecurityNumber(this.socialSecurityNr));
    },
    saveSocial() {
      this.saveSocialTick = !this.saveSocialTick;
    },
    updateTerms(key: string, value: boolean) {
      this.terms[key].accepted = value;
    },
    saveSSN(): void {
      try {
        if (this.saveSocialTick) {
          localStorage.setItem(localStorageKeys.socialSecurityNumber, this.socialSecurityNr);
        } else {
          localStorage.removeItem(localStorageKeys.socialSecurityNumber);
        }
      } catch (_) {}
    },
    saveTerms() {
      const terms = {
        privacyPolicy: this.terms.privacyPolicy.accepted,
        conditions: this.terms.conditions.accepted,
      };

      try {
        localStorage.setItem(localStorageKeys.terms, JSON.stringify(terms));
      } catch (_) {
      }
    },
    setRegistrationData() {
      try {
        localStorage.setItem(localStorageKeys.reference, this.reference);
        localStorage.setItem(localStorageKeys.inviteToken, this.inviteToken);
      } catch (_) { }
    },
    setPhoneDesignAccepted() {
      if (this.showPhoneDesign && this.showPhoneDesign === 'false') {
        this.$cookies.set(PHONE_DESIGN_ACCEPT, 'accepted');
      }
    },
  },
  mounted() {
    this.reference = this.$route.query.reference;
    this.inviteToken = this.$route.query.invitetoken;
    this.showPhoneDesign = this.$route.query.showphonedesign;

    this.$nextTick(() => this.collect());
    try {
      const storedSecurityNumber = localStorage.getItem(localStorageKeys.socialSecurityNumber);
      const terms = JSON.parse(localStorage.getItem(localStorageKeys.terms) || '{}');

      if (storedSecurityNumber) {
        this.socialSecurityNr = storedSecurityNumber;
        this.saveSocialTick = true;
      }

      if (Object.keys(terms)) {
        this.terms.conditions.accepted = terms.conditions;
        this.terms.privacyPolicy.accepted = terms.privacyPolicy;
      }
    } catch (_) {}
  },
});
