









import Vue from 'vue';
// @ts-ignore
import { MdAvatar } from 'vue-material/dist/components';

Vue.use(MdAvatar);

export default Vue.extend({
  name: 'AvatarImage',
  props: {
    img: String,
    altText: String,
  },
});
