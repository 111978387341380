

































































import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { IMAGE_URL } from '../environment';
import Purchase from '../models/Purchase';
import { toTitleCase } from '../utils/helpers';

export default Vue.extend({
  name: 'PurchaseListItem',
  props: {
    item: Object as PropType<Purchase>,
    allowNavigation: Boolean,
  },
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
      responder: 'getResponder',
    }),
    slicedDescription() {
      return (this.item.description.length > 50)
        ? this.item.description.slice(0, 47).concat('...')
        : this.item.description;
    },
    statusText() {
      if ((this.item.status === 'approved')
        && this.item.isAutoApproved) return 'Automatiskt godkänt inköp';
      if (this.item.status === 'approved') return 'Godkänt inköp';
      if (this.item.status === 'rejected') return 'Nekat inköp';
      if (this.item.status === 'cleared') return 'Inköpt samt betalt av båda';
      // @ts-ignore
      const responderText = (this.item.responder === this.user.publicId)
        ? 'dig'
        : this.responder.name;
      return `Inväntar godkännande av ${responderText}`;
    },
    statusColor() {
      if (this.item.status === 'rejected') return 'rejected';
      if (this.item.status === 'approved') return 'approved';
      return 'waiting';
    },
    purchaseImg() {
      return (!this.item.imageName) ? '' : `${IMAGE_URL}${this.item.imageName}`;
    },
    itemName() {
      return toTitleCase(this.item.name);
    },
  },
  methods: {
    gotoViewPurchase() {
      if (this.allowNavigation) {
        this.$router.push({
          name: 'View purchase',
          // @ts-ignore
          params: { purchaseId: this.item.publicId },
        });
      }
    },
  },
});
