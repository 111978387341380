






















import Vue from 'vue';

// @ts-ignore
import { MdButton, MdIcon } from 'vue-material/dist/components';
import HTMLButtonEvent from '../models/HTMLButtonEvent';

Vue.use(MdButton, MdIcon);

export default Vue.extend({
  name: 'SecondaryButton',
  props: [
    'icon',
    'text',
    'type',
    'ripple',
  ],
  methods: {
    sendUp(e: HTMLButtonEvent) {
      e.preventDefault();
      return this.$emit('click');
    },
  },
});
