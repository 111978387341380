


































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
// @ts-ignore
import { MdButton, MdDialog } from 'vue-material/dist/components';
import SwishResult from '../components/SwishResult.vue';
import { CLEAR_URL, SWISH_URL } from '../environment';
import PurchaseList from '../components/PurchaseList.vue';
import PrimaryButton from '../components/PrimaryButton.vue';
import SecondaryButton from '../components/SecondaryButton.vue';
import Purchase from '../models/Purchase';
import { purchaseDiff } from '../utils/purchaseDiff';
import { getMobileOperatingSystem } from '../utils/helpers';

Vue.use(MdButton, MdDialog);

export default Vue.extend({
  name: 'SplitPurchases',
  components: {
    PurchaseList,
    PrimaryButton,
    SecondaryButton,
    SwishResult,
  },
  data: () => ({
    disableButton: false,
    showButton: true,
    areInDebt: false,
    owedText: '',
    debt: 0,
  }),
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
      child: 'getChild',
    }),
    otherParent(): any {
      if (this.user.name === this.child.parent1.name) return this.child.parent2;
      return this.child.parent1;
    },
    os(): string {
      return getMobileOperatingSystem(navigator);
    },
    items(): Purchase[] {
      const items = this.$store.state.purchases;
      if (!items) return [];
      return items.filter((x: Purchase) => x.completed && x.status === 'approved');
    },
    showSwishStatus(): boolean {
      return (!!this.$route.query.status);
    },
    swishStatus(): string {
      // @ts-ignore
      return this.$route.query.status ?? '';
    },
  },
  methods: {
    async startSwish() {
      this.disableButton = true;
      const data = {
        amount: Math.abs(this.debt),
        items: this.items.map((x: Purchase) => x.publicId),
        otherParentId: this.otherParent.publicId,
      };
      this.$http.post(SWISH_URL, data)
        .then((result: any) => {
          this.showButton = false;
          this.openSwish(result.data.swishUrl);
        })
        .catch(() => {
          this.showButton = false;
          this.$router.push({ name: 'SplitPurchases', query: { status: 'error' } });
        });
    },
    openSwish(url: string) {
      const btn = document.createElement('button');
      btn.onclick = () => window.location.assign(url);
      document.body.appendChild(btn);
      btn.click();
      document.body.removeChild(btn);
    },
    async clearPurchases() {
      this.disableButton = true;
      const url = CLEAR_URL;
      const items = this.items.map((x: Purchase) => ({ purchase: x.publicId }));
      this.$http
        .patch(url, items)
        .then(() => {
          this.showButton = false;
          this.$router.push({ name: 'SplitPurchases', query: { status: 'paid' } });
        })
        .catch(() => {
          this.showButton = false;
          this.$router.push({ name: 'SplitPurchases', query: { status: 'error' } });
        });
    },
    setOwedText() {
      const purchDiff = purchaseDiff(this.items);
      this.debt = purchDiff.parent1.debt - purchDiff.parent2.debt;

      if (this.debt === 0) {
        return 'Ni har inga skulder till varandra. Du borde inte ha hamnat i den här vyn.';
      }
      if (this.user.publicId === purchDiff.parent1.id) {
        this.areInDebt = this.debt > 0;
        return (this.areInDebt)
          ? `Du är skyldig ${this.otherParent.name} ${this.debt}kr.`
          : `${this.otherParent.name} är skyldig dig ${Math.abs(this.debt)}kr.`;
      }
      this.areInDebt = this.debt < 0;
      return (this.areInDebt)
        ? `Du är skyldig ${this.otherParent.name} ${Math.abs(this.debt)} kr.`
        : `${this.otherParent.name} är skyldig dig ${this.debt} kr.`;
    },
  },
  mounted() {
    this.$nextTick(() => {
    // @ts-ignore
      this.owedText = this.setOwedText();
    });
  },
});
