










import Vue from 'vue';

// @ts-ignore
import { MdSnackbar } from 'vue-material/dist/components';

Vue.use(MdSnackbar);

export default Vue.extend({
  data: () => ({
    position: 'center',
  }),
  props: [
    'snackbarMessage',
    'showSnackbar',
  ],
});
