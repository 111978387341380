



































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import Purchase from '../models/Purchase';
import AvatarImage from './AvatarImage.vue';
import { IMAGE_ACCOUNT_URL, IMAGE_URL } from '../environment';
import HTMLInputEvent from '../models/HTMLInputEvent';

export default Vue.extend({
  name: 'PercentDivider',
  components: {
    AvatarImage,
  },
  data: () => ({
    createdByAvatar: '' as string,
    responderAvatar: '' as string,
    createdByPercentPart: 0 as number,
    responderPercentPart: 0 as number,
  }),
  props: {
    preSplit: Number,
    newChild: Boolean,
    purchase: {
      type: Object as () => Purchase,
    },
  },
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
      responder: 'getResponder',
      child: 'getChild',
    }),
    responderName() {
      return this.responder ? this.responder.name : 'Den andre föräldern';
    },
  },
  methods: {
    changePercent(e: HTMLInputEvent): void {
      let value = parseInt(e.target.value, 10);
      value = (value < 0) ? 0 : value;
      value = (value > 100) ? 100 : value;
      this[e.target.id](value);
      this.$emit('createdByPercent', this.createdByPercentPart);
    },
    changeResponderPercent(value: number): void {
      this.responderPercentPart = value;
      this.createdByPercentPart = 100 - this.responderPercentPart;
    },
    changeCreatedByPercentPart(value: number): void {
      this.createdByPercentPart = value;
      this.responderPercentPart = 100 - this.createdByPercentPart;
    },
  },
  async mounted() {
    if (this.purchase) {
      this.createdByPercentPart = this.purchase.createdByPercentPart;
      this.responderPercentPart = 100 - this.purchase.createdByPercentPart;
      await this.$http
        // @ts-ignore
        .get(`${IMAGE_ACCOUNT_URL}${this.purchase.createdBy.publicId}`)
        .then((res) => { this.createdByAvatar = res.status === 200 ? `${IMAGE_URL}${res.data}` : ''; })
        .catch(() => { this.createdByAvatar = ''; });
      // @ts-ignore
      if (this.purchase.responder.publicId) {
        await this.$http
        // @ts-ignore
          .get(`${IMAGE_ACCOUNT_URL}${this.purchase.responder.publicId}`)
          .then((res) => {
            if (res.data) {
              this.responderAvatar = `${IMAGE_URL}${res.data}`;
            } else {
              this.responderAvatar = '';
            }
          })
          .catch(() => { this.responderAvatar = ''; });
      } else {
        this.responderAvatar = '';
      }
    } else if (this.child) {
      this.createdByPercentPart = (this.user.publicId === this.child.parent1.publicId)
        ? parseInt(this.child.parent1PercentPart, 10)
        : 100 - parseInt(this.child.parent1PercentPart, 10);
      this.responderPercentPart = 100 - this.createdByPercentPart;
      this.createdByAvatar = (this.user.imageName)
        ? `${IMAGE_URL}${this.user.imageName}`
        : '';
      if (this.responder.publicId) {
        await this.$http
          .get(`${IMAGE_ACCOUNT_URL}${this.responder.publicId}`)
          .then((res) => {
            if (res.data) {
              this.responderAvatar = `${IMAGE_URL}${res.data}`;
            } else {
              this.responderAvatar = '';
            }
          })
          .catch(() => { this.responderAvatar = ''; });
      } else {
        this.responderAvatar = '';
      }
    } else {
      this.createdByPercentPart = 50;
      this.responderPercentPart = 100 - this.createdByPercentPart;
      this.createdByAvatar = (this.user.imageName)
        ? `${IMAGE_URL}${this.user.imageName}`
        : '';
      this.responderAvatar = '';
    }
    if (this.preSplit !== 50) {
      this.createdByPercentPart = this.preSplit;
      this.responderPercentPart = 100 - this.preSplit;
    }
  },
});
