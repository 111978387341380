





















import Vue, { PropType } from 'vue';
import Purschase from '../models/Purchase';
import PurchaseListItem from './PurchaseListItem.vue';

export default Vue.extend({
  name: 'PurchaseList',
  components: {
    PurchaseListItem,
  },
  props: {
    header: String,
    noItemsText: String,
    items: Array as PropType<Array<Purschase>>,
    allowNavigation: { default: true, type: Boolean },
  },
});

