












































































































































































import Vue from 'vue';
// @ts-ignore
import { MdDialog, MdButton } from 'vue-material/dist/components';
import PrimaryButton from '../components/PrimaryButton.vue';
import SecondaryButton from '../components/SecondaryButton.vue';
import { PHONE_DESIGN_ACCEPT } from '../environment';

Vue.use(MdDialog, MdButton);

export default Vue.extend({
  name: 'Home',
  components: {
    PrimaryButton,
    SecondaryButton,
  },
  data: () => ({
    reference: '' as string,
    inviteToken: '' as string,
    showPhoneDesign: 'true',
  }),
  methods: {
    goToLogin() {
      this.$router.push({
        name: 'Login',
        query: {
          reference: this.reference,
          invitetoken: this.inviteToken,
          showphonedesign: this.showPhoneDesign,
        },
      });
    },
    designConsent(): void {
      this.showPhoneDesign = 'false';
      if (document.getElementById('design-accept')) {
        // @ts-ignore
        document.getElementById('design-accept').remove();
      }
    },
  },
  mounted() {
    try {
      const phonedesign = this.$cookies.get(PHONE_DESIGN_ACCEPT);
      if (phonedesign) this.showPhoneDesign = 'false';
    } catch (_) {}
    if (this.$route.query.invitationcode) {
      const inviteToken: any = this.$route.query.invitationcode;
      this.inviteToken = inviteToken;
      this.reference = 'invited';
    } else if (this.$route.query.reference) {
      const { reference }: any = this.$route.query;
      this.reference = reference;
    }
  },
});
