












import Vue from 'vue';
import { mapGetters } from 'vuex';
// @ts-ignore
import { MdDialog } from 'vue-material/dist/components';
import { CHILD_URL } from '../environment';
import ConnectResult from '../components/ConnectResult.vue';

Vue.use(MdDialog);

export default Vue.extend({
  name: 'ChildAdded',
  components: {
    ConnectResult,
  },
  data: () => ({
    childName: 'barnet',
  }),
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
    }),
  },
  methods: {
    sendToRegisterChild() {
      this.$router.push({ name: 'Overview' });
    },
    fetchChild(): void {
      const url = CHILD_URL;
      this.$http
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.childName = response.data.name;
            this.$store.commit('setChild', response.data);
            this.$store.commit('setResponder',
              (this.user.publicId === response.data.parent1.publicId)
                ? response.data.parent2
                : response.data.parent1);
          }
        })
        .catch((error: any) => {
          if (error.response.status === 404) {
            this.$router.replace({
              name: 'NoChild',
            });
          }
        });
    },
  },
  mounted(): void {
    this.$nextTick(() => {
      // @ts-ignore
      this.fetchChild();
    });
  },
});
