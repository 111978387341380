interface LocalStorageKeys {
    socialSecurityNumber: string;
    terms: string;
    inviteToken: string;
    reference: string;
    necessaryCookies: string;
    performanceCookies: string;
}

export const localStorageKeys: LocalStorageKeys = {
  socialSecurityNumber: 'socialSecurityNumber',
  terms: 'terms',
  inviteToken: 'inviteToken',
  reference: 'reference',
  necessaryCookies: 'necessaryCookies',
  performanceCookies: 'performanceCookie',
};
