

























import Vue from 'vue';
import SecondaryButton from './SecondaryButton.vue';

export default Vue.extend({
  name: 'EditDialog',
  components: {
    SecondaryButton,
  },
  props: {
    mode: String,
  },
  computed: {
    text(): string {
      switch (this.mode) {
        case 'edit':
          return 'Ändringarna är sparade';
        case 'autoapproved':
          return 'Inköpet är skapat och det godkändes automatiskt.';
        case 'created':
          return 'Inköpet är skapat och inväntar godkännande.';
        default:
          return '';
      }
    },
  },
  methods: {
    goBack(): void {
      this.$router.push({ name: 'Overview' });
    },
  },
});

