






































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  mounted() {
    // @ts-ignore
    const scrollTo: string = this.$route.path;

    if (scrollTo) {
      const el: HTMLElement | null = document.getElementById(scrollTo);
      if (el) {
        el.scrollIntoView();
      }
    }
  },
});
