import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import Vue from 'vue';
// @ts-ignore
import VueGtm from '@gtm-support/vue2-gtm';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import './assets/global.css';
import {
  ERROR_URL, GTM_ID, GTM_AUTH, GTM_PREVIEW, NODE_ENV,
} from './environment';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(VueAxios, axios);
Vue.use(VueCookies);

Vue.use(VueGtm, {
  id: GTM_ID,
  queryParams: {
    // eslint-disable-next-line
    gtm_auth: GTM_AUTH,
    // eslint-disable-next-line
    gtm_preview: GTM_PREVIEW,
  },
  defer: false,
  compatibility: false,
  enabled: false,
  // (this.$gtm.enable(true))
  debug: !(NODE_ENV === 'production'),
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTicK: false,
});

Vue.config.errorHandler = (err: Error, vm: Vue, info: string) => {
  axios.post(ERROR_URL, {
    name: err.name, stack: err.stack, message: err.message, info,
  });
};

axios.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  const newConfig = { ...config };
  newConfig.headers['x-access-token'] = `Bearer ${store.state.token}`;
  return newConfig;
});

axios.interceptors.response.use((response: AxiosResponse<any>): AxiosResponse<any> => {
  store.commit('setToken', response.headers['x-access-token']);
  return response;
}, (error: any): any => {
  if (error.response.status === 401) {
    store.commit('reset');
    return router.push({ name: 'Home' });
  }
  return Promise.reject(error);
});
