






































































































































































import Vue from 'vue';
import moment from 'moment';
// @ts-ignore
import { MdButton } from 'vue-material/dist/components';
import { mapGetters } from 'vuex';
import {
  PURCHASE_APPROVEORREJECT_URL,
  PURCHASE_BOUGHT_URL,
  IMAGE_URL,
  PURCHASE_URL,
} from '../environment';
import PrimaryButton from '../components/PrimaryButton.vue';
import SecondaryButton from '../components/SecondaryButton.vue';
import { toTitleCase } from '../utils/helpers';

Vue.use(MdButton);

export default Vue.extend({
  name: 'ViewPurchase',
  components: {
    PrimaryButton,
    SecondaryButton,
  },
  data: () => ({
    errorMessage: '' as string,
    otherParent: '',
    item: {} as any,
  }),
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
      child: 'getChild',
      stateResponder: 'getResponder',
    }),
    creator() {
      if (!this.user || !this.item) return '';
      if (!this.item.createdBy) return '';
      return this.item.createdBy.name;
    },
    responder() {
      return (this.item.responder.name === '')
        ? this.stateResponder.name
        : this.item.responder.name;
    },
    payedByCreator() {
      if ((this.item.completed && this.item.status !== 'cleared')
        || this.item.status === 'rejected') {
        return `${this.item.price} kr`;
      }
      return `${(this.item.price * this.item.createdByPercentPart) / 100} kr`;
    },
    creatorPaymentText() {
      return (this.item.completed)
        ? ` betalt av ${this.creator}`
        : ` ska betalas av ${this.creator}`;
    },
    awaitingResponderPaymentText() {
      if (this.item.status === 'approved') {
        return ` betalt av ${this.creator} 
           ${this.payedByResponder} ska betalas av ${this.responder}`;
      }
      if (this.item.status === 'waiting') {
        return ` betalt av ${this.creator} 
        \n\t ${this.payedByResponder} inväntar svar från ${this.responder}.`;
      }
      if (this.item.status === 'cleared') {
        return ` betalt av ${this.creator} 
          samt ${this.payedByResponder} betalt av ${this.responder}.`;
      }
      return `Vi trodde vi hade helgarderat oss mot missar i appen, 
        men vi hade fel, den här texten borde du inte se`;
    },
    payedByResponder() {
      return `${(this.item.price * (100 - this.item.createdByPercentPart)) / 100} kr`;
    },
    responderPaymentText() {
      return (this.item.status === 'cleared')
        ? ` betalt av ${this.responder}`
        : ` ska betalas av ${this.responder}`;
    },
    imResponder() {
      return (this.user.publicId === this.item.responder.publicId);
    },
    statusStyle() {
      if (this.item.status === 'approved' || this.item.status === 'cleared') return 'approved';
      if (this.item.status === 'rejected') return 'rejected';
      return 'color-grey';
    },
    statusText() {
      if (this.item.status === 'approved' || this.item.status === 'cleared') return 'Godkänt inköp av ';
      if (this.item.status === 'rejected') return 'Nekat inköp av ';
      return 'Inväntar godkännande av ';
    },
    purchaseImg() {
      return (!this.item.imageName) ? '' : `${IMAGE_URL}${this.item.imageName}`;
    },
    isAutoApproved() {
      return this.item.isAutoApproved;
    },
    isCompleted() {
      return this.item.completed;
    },
    createdAt() {
      return moment(this.item.createdAt).format('D MMM YYYY - HH:mm');
    },
    validLink(): boolean {
      if (!this.item.link) return false;
      return true;
    },
    itemName(): string {
      return toTitleCase(this.item.name);
    },
  },
  methods: {
    editPurchase() {
      this.$router.push({
        name: 'Create purchase',
        // @ts-ignore
        params: { purchaseId: this.item.publicId },
      });
    },
    fetchOnePurchase(purchaseId: string) {
      const url = `${PURCHASE_URL}${this.child.publicId}/${purchaseId}`;
      this.$http.get(url)
        .then((res: any) => {
          this.item = res.data;
        })
        .catch((error: any) => {
          if (error.response.status === 404) this.$router.go(-1);
          else console.log(error);
        });
    },
    completePurchase() {
      if (this.item.createdBy.publicId || this.item.responder.publicId === this.user.publicId) {
        this.$http
          .patch(`${PURCHASE_BOUGHT_URL}${this.item.publicId}`)
          .then((res) => {
            if (res.status === 200) {
              if (this.item.responder.publicId === this.user.publicId
              && this.item.status !== 'approved') {
                const approv = {
                  userPublicId: this.user.publicId,
                  status: 'approved',
                };
                this.$http
                  .patch(`${PURCHASE_APPROVEORREJECT_URL}${this.item.publicId}`, approv)
                  .then((response) => {
                    if (response.status === 200) {
                      this.$router.go(-1);
                    }
                  })
                  .catch((error) => {
                    this.errorMessage = error.response.data;
                  });
              } else {
                this.$router.go(-1);
              }
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.errorMessage = error.response.data;
            } else if (error.response.status === 404) {
              this.errorMessage = error.response.data;
              this.$router.go(-1);
            }
          });
      }
    },
    async approve() {
      if (!this.item.responder.publicId === this.user.publicId) return;
      const data = {
        status: 'approved',
      };
      this.$http
        .patch(`${PURCHASE_APPROVEORREJECT_URL}${this.item.publicId}`, data)
        .then((res) => {
          if (res.status === 200) {
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errorMessage = error.response.data;
          } else if (error.response.status === 404) {
            this.errorMessage = error.response.data;
            this.$router.go(-1);
          }
        });
    },
    async reject() {
      if (!this.item.responder.publicId === this.user.publicId) return;
      const data = {
        status: 'rejected',
      };
      this.$http
        .patch(`${PURCHASE_APPROVEORREJECT_URL}${this.item.publicId}`, data)
        .then((res) => {
          if (res.status === 200) {
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errorMessage = error.response.data;
          } else if (error.response.status === 404) {
            this.errorMessage = error.response.data;
            this.$router.go(-1);
          }
        });
    },
    async removePurchase() {
      if (!this.item.createdBy.publicId === this.user.publicId) return;
      this.$http
        .delete(`${PURCHASE_URL}${this.item.publicId}`)
        .then((res) => {
          if (res.status === 200) {
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errorMessage = error.response.data;
          } else if (error.response.status === 404) {
            this.errorMessage = error.response.data;
            this.$router.go(-1);
          }
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      // @ts-ignore
      this.fetchOnePurchase(this.$route.params.purchaseId);
    });
  },
});
