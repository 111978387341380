







































import Vue from 'vue';

// @ts-ignore
import { MdButton } from 'vue-material/dist/components';
import PrimaryButton from './PrimaryButton.vue';
import SecondaryButton from './SecondaryButton.vue';
import { getMobileOperatingSystem } from '../utils/helpers';

Vue.use(MdButton);

export default Vue.extend({
  components: { PrimaryButton, SecondaryButton },
  name: 'BankId',
  props: {
    login: Function,
    bankidErrorMessage: String,
    autostarttoken: String,
    orderRef: String,
    ssn: String,
    save: Boolean,
    cancel: Function,
  },
  computed: {
    url(): string {
      const os = getMobileOperatingSystem(navigator);
      if (os === 'Android') {
        return `bankid:///?autostarttoken=${this.autostarttoken}&redirect=null`;
        // return `https://app.bankid.com/?autostarttoken=${this.autostarttoken}&redirect=null`;
      }
      if (os === 'iOS') {
        // return `https://app.bankid.com/?autostarttoken=${this.autostarttoken}&redirect=${window.location.origin}`;
        return `https://app.bankid.com/?autostarttoken=${this.autostarttoken}&redirect=${window.location.origin}?ref=${this.orderRef}&ssn=${this.ssn}&s=${this.save}`;
      }
      return `https://app.bankid.com/?autostarttoken=${this.autostarttoken}${window.location.origin}`;
      // return `bankid:///?autostarttoken=${this.autostarttoken}&redirect=${window.location.origin}?ref=${this.orderRef}&ssn=${this.ssn}&s=${this.save}`;
    },
  },
  methods: {
    openBankID(): void {
      const el = document.getElementById('bankid-url');
      if (el) {
        el.click();
      }
    },
  },
});
