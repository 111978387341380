


































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { AxiosResponse } from 'axios';
import PurchaseList from '../components/PurchaseList.vue';
import { CHILD_URL, PURCHASE_URL, IMAGE_URL } from '../environment';
import Purchase from '../models/Purchase';
import { localStorageKeys } from '../utils/constants';
import DashboardHeader from '../components/DashboardHeader.vue';
import PrimaryButton from '../components/PrimaryButton.vue';
import SecondaryButton from '../components/SecondaryButton.vue';
import { purchaseDiff } from '../utils/purchaseDiff';
import { getMobileOperatingSystem } from '../utils/helpers';

export default Vue.extend({
  name: 'Overview',
  components: {
    PurchaseList,
    DashboardHeader,
    PrimaryButton,
    SecondaryButton,
  },
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
      child: 'getChild',
      purchases: 'getPurchases',
      responder: 'getResponder',
    }),
    isDesktop(): boolean {
      return getMobileOperatingSystem(navigator) === 'unknown';
    },
    proposals(): Purchase[] {
      return (this.purchases)
        ? this.purchases.filter((x: Purchase) => !x.completed)
        : [] as Purchase[];
    },
    bought(): Purchase[] {
      return (this.purchases)
        ? this.purchases.filter((x: Purchase) => x.completed)
        : [] as Purchase[];
    },
    purchasedifference(): number {
      // @ts-ignore
      const purchdiff = purchaseDiff(this.bought);
      if (!purchdiff || purchdiff.diffsum === 0) return 0;
      if (purchdiff.parent1.id === this.user.publicId) {
        return (purchdiff.parent1.debt - purchdiff.parent2.debt);
      }
      return (purchdiff.parent2.debt - purchdiff.parent1.debt);
    },
    otherParent(): string {
      return (this.child)
        ? this.responder.name
        : 'hämtar';
    },
    childImg(): string {
      return (!this.child.imageName) ? '' : `${IMAGE_URL}${this.child.imageName}`;
    },
  },
  methods: {
    gotoCreatePurchase(): void {
      this.$router.push({ name: 'Create purchase' });
    },
    goToSplitPurchases(): void {
      this.$router.push({ name: 'SplitPurchases' });
    },
    getChildOrRegisterChild(): void {
      const url = CHILD_URL;
      this.$http
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('setChild', response.data);
            this.$store.commit('setResponder',
              (this.user.publicId === response.data.parent1.publicId)
                ? response.data.parent2
                : response.data.parent1);

            // @ts-ignore
            this.fetchPurchases();
          }
        })
        .catch((error: any) => {
          if (error.response.status === 404) {
            this.$router.replace({
              name: 'NoChild',
            });
          }
        });
    },
    fetchPurchases(): void {
      this.$http.get(`${PURCHASE_URL}${this.child.publicId}`)
        .then((res: AxiosResponse<Purchase[]>) => {
          this.$store.commit('setPurchases', res.data);
        })
        .catch((error: any) => {
          console.log(error);
        });
    },
  },
  mounted(): void {
    // try {
    //   const performanceCookies = localStorage.getItem(localStorageKeys.performanceCookies);
    //   if (performanceCookies) {
    //     console.log('häst');
    //     this.$gtm.enable(true);
    //   }
    // } catch {}
    localStorage.removeItem(localStorageKeys.reference);
    this.$nextTick(() => {
      // @ts-ignore
      this.getChildOrRegisterChild();
    });
  },
});
