

























import Vue from 'vue';

// @ts-ignore
import { MdField, MdTextarea } from 'vue-material/dist/components';

Vue.use(MdField, MdTextarea);

export default Vue.extend({
  name: 'InputField',
  props: [
    'value',
    'type',
    'disabled',
    'placeholder',
    'required',
    'label',
    'helper',
    'hasMessage',
    'errorMessage',
  ],
  computed: {
    inputValue: {
      get() {
        // @ts-ignore
        return this.value;
      },
      set(val) {
        // @ts-ignore
        this.$emit('input', val);
      },
    },
    messageClass() {
      return {
        'md-invalid': this.hasMessage,
      };
    },
  },
});
