

























import Vue from 'vue';
import Cropper from 'cropperjs';
import PrimaryButton from './PrimaryButton.vue';
import SecondaryButton from './SecondaryButton.vue';

export default Vue.extend({
  name: 'ImageCropper',
  components: {
    PrimaryButton,
    SecondaryButton,
  },
  props: {
    src: String,
  },
  data() {
    return {
      cropper: {} as Cropper,
      destination: {},
      image: {} as HTMLImageElement,
    };
  },
  methods: {
    abort() {
      this.$emit('clearFile');
    },
    saveImage() {
      this.cropper.getCroppedCanvas({
        width: 300,
        height: 300,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      }).toBlob((blob) => {
        this.$emit('saveFile', blob);
      });
    },
  },
  mounted() {
    this.image = this.$refs.image as HTMLImageElement;
    this.cropper = new Cropper(this.image, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.destination = canvas.toDataURL('image/jpeg');
      },
    });
  },
});
