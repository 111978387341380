export const ACCOUNT_URL = process.env.VUE_APP_ACCOUNT_URL;
export const BANK_ID_URL = process.env.VUE_APP_BANK_ID_URL;
export const CHILD_URL = process.env.VUE_APP_CHILD_URL;
export const PURCHASE_URL = process.env.VUE_APP_PURCHASE_URL;
export const PURCHASE_EDIT_URL = process.env.VUE_APP_PURCHASE_EDIT_URL;
export const PURCHASE_APPROVEORREJECT_URL = process.env.VUE_APP_PURCHASE_APPROVEORREJECT_URL;
export const PURCHASE_BOUGHT_URL = process.env.VUE_APP_PURCHASE_BOUGHT_URL;
export const CLEAR_URL = process.env.VUE_APP_CLEAR_URL;
export const IMAGE_URL = process.env.VUE_APP_IMAGE_URL;
export const IMAGE_ACCOUNT_URL = process.env.VUE_APP_IMAGE_ACCOUNT_URL;
export const STATISTICS_URL = process.env.VUE_APP_STATISTICS_URL;
export const ERROR_URL = process.env.VUE_APP_ERROR_URL;
export const INVITE_URL = process.env.VUE_APP_INVITE_URL;
export const SWISH_URL = process.env.VUE_APP_SWISH_URL;
export const NODE_ENV = process.env.VUE_APP_NODE_ENV;

export const INVITE_COOKIE_SECRET = process.env.VUE_APP_INVITE_COOKIE_SECRET;
export const INVITE_COOKIE_NAME = process.env.VUE_APP_INVITE_COOKIE_NAME;
export const INVITE_COOKIE_CODE = process.env.VUE_APP_INVITE_COOKIE_CODE;
export const POPUP_COOKIE_INFO_NAME = process.env.VUE_APP_POPUP_COOKIE_INFO_NAME;
export const PHONE_DESIGN_ACCEPT = process.env.VUE_APP_PHONE_DESIGN_ACCEPT;

export const GTM_ID = process.env.VUE_APP_GTM_ID;
export const GTM_AUTH = process.env.VUE_APP_GTM_AUTH;
export const GTM_PREVIEW = process.env.VUE_APP_GTM_PREVIEW;
