


















































































































































































































































































































































































import Vue from 'vue';
// @ts-ignore
import { MdTable } from 'vue-material/dist/components';

Vue.use(MdTable);

export default Vue.extend({
  name: 'Cookie-Information',
  methods: {
    goToStart(): void {
      this.$router.push({ name: 'Home' });
    },
    scrollToCookies(): void {
      const cookieElem = document.getElementById('cookie-specifics');
      if (cookieElem) {
        cookieElem.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.scrolltocookies === 'true') {
        this.scrollToCookies();
      }
    });
  },
});

