import Purchase from '@/models/Purchase';

function debtCalculator(id: string, purchases: Purchase[]): number {
  return purchases.filter((x: Purchase) => x.purchasedBy !== id)
    .map((y: Purchase) => (y.price * ((100 - (y.responder === id
      ? y.createdByPercentPart : 100 - y.createdByPercentPart)) / 100)))
    .reduce((a: number, b: number) => a + b, 0);
}

function purchaseTotal(purchases: Purchase[]): number {
  return purchases.map((x: Purchase) => (x.price))
    .reduce((a: number, b: number) => a + b, 0);
}

function diff(a: number, b: number): number {
  if (a > b) return a - b;
  return b - a;
}

export function purchaseDiff(purchases: Purchase[]): any {
  const arrayOfPurchases = purchases.filter((x: Purchase) => (
    x.completed && (x.status === 'approved')));
  if (arrayOfPurchases.length === 0) return null;

  const parent1Id = arrayOfPurchases[0].createdBy;
  const parent2Id = arrayOfPurchases[0].responder;
  const parent1Debt = debtCalculator(parent1Id, arrayOfPurchases);
  const parent2Debt = debtCalculator(parent2Id, arrayOfPurchases);

  return {
    total: purchaseTotal(arrayOfPurchases),
    items: arrayOfPurchases,
    diffsum: diff(parent1Debt, parent2Debt),
    parent1: { id: parent1Id, debt: parent1Debt },
    parent2: { id: parent2Id, debt: parent2Debt },
  };
}
