

















import Vue from 'vue';

// @ts-ignore
import { MdCheckbox } from 'vue-material/dist/components';

Vue.use(MdCheckbox);

export default Vue.extend({
  name: 'PrimaryCheckbox',

  props: ['text', 'value', 'link'],
  computed: {
    boxValue: {
      get() {
        // @ts-ignore
        return this.value;
      },
      set(val) {
        // @ts-ignore
        this.$emit('change', val);
      },
    },
  },
});
