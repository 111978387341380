import axios from 'axios';
import { IMAGE_URL } from '../environment';

export const toTitleCase = (word: string) => {
  if (!word) return word;
  return word[0].toUpperCase() + word.substr(1).toLowerCase();
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const postImage = async (fData: FormData): Promise<string> => axios
  .post(IMAGE_URL, fData)
  .then((response) => {
    if (response.status === 201) {
      return response.data;
    }
    return '';
  })
  .catch(() => '');

export const checkURLForHttp = (url: string): string => ((!url.includes('http') || !url.includes('https'))
  ? `https://${url}`
  : url);

export const getMobileOperatingSystem = (navigator: Navigator): string => {
  const { userAgent } = navigator;
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }
  return 'unknown';
};

export const dataURLToBlob = (dataURL: string): Blob => {
  const BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts: string[] = dataURL.split(',');
    const contentType: string = parts[0].split(':')[1];
    const raw: string = parts[1];

    return new Blob([raw], { type: contentType });
  }

  const parts: string[] = dataURL.split(BASE64_MARKER);
  const contentType: string = parts[0].split(':')[1];
  const raw: string = window.atob(parts[1]);
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength);

  // @ts-ignore
  for (let i = 0; i < rawLength; i += 1) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};
